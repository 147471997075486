import Swiper, {EffectFade} from 'swiper';
import { Navigation } from 'swiper';
Swiper.use([Navigation, EffectFade]);


const swiper = new Swiper('.carttourswiper-swiper' , {
    fadeEffect: { crossFade: true },
    loop: true,
    effect: "fade",
    observeParents: true,
    slidesPerView: 1,
    autoplay: {
        delay: 2500,
      },
  });
