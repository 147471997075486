import Swiper, {EffectFade} from 'swiper';
import { Navigation } from 'swiper';
Swiper.use([Navigation, EffectFade]);

const swiperEl = document.querySelector(".headerswiper");

const swiper = new Swiper( swiperEl, {
    fadeEffect: { crossFade: true },
    loop: true,
    effect: "fade",
    observeParents: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    modules: [ Navigation ],
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },

  });