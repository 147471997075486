import Swiper from 'swiper';
import { Navigation } from 'swiper';

const swiper = new Swiper('.photogallery_swiper', {
    loop: true,
    observeParents: true,
    slidesPerView: 5,
    slidesPerGroup: 1,
    spaceBetween: 10,
    breakpoints: {

      320: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      900:{
        slidesPerView: 5,
        spaceBetween: 40
      }
    }
  });