import Swiper from 'swiper';
import { Navigation } from 'swiper';

const swiper = new Swiper('.feedback-swiper', {
    loop: true,
    observeParents: true,
    slidesPerView: 4,
    slidesPerGroup: 1,
    spaceBetween: 32,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    modules: [ Navigation ],
    breakpoints: {

      320: {
        slidesPerView: 1,
        spaceBetween: 30
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      900:{
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  });