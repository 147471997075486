const btnChoosen = document.querySelectorAll('.js--chooseBtnsProd')
const chooseContent = document.querySelectorAll('.js--choosenCont'); 

    for(let el of btnChoosen){
        el.addEventListener('click', function () {
            if (!(this.classList.contains('active'))) {
                for(let itm of btnChoosen){
                    itm.classList.remove('active');
                    this.classList.add('active');
                }
                
                for(let item of chooseContent){
                        item.classList.toggle('hidden');
                        item.classList.toggle('active');
                    }
                
            }
        })
    }





    