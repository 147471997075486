import Swiper, {EffectFade} from 'swiper';
import { Navigation } from 'swiper';
Swiper.use([Navigation, EffectFade]);


var swiperHotel = new Swiper(".mySwiper", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 6,
    freeMode: true,
    watchSlidesProgress: true,
  });
  var swiperHotel2 = new Swiper(".mySwiper2", {
    loop: true,
    spaceBetween: 10,

    thumbs: {
      swiper: swiperHotel,
    },
  });
