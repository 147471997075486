const headerOverlay = document.querySelector('.js-header')
const burgerBtn = document.querySelector('.js-burger')
const body = document.querySelector('body')
const menu = document.querySelector('.js-menu')


burgerBtn.addEventListener('click', () =>{
    headerOverlay.classList.toggle('active')
    body.classList.toggle('lock')
    burgerBtn.classList.toggle('active')
    menu.classList.toggle('active')
    
})

// modal

const  modalButtons = document.querySelector('.js-open-modal')
const overlaymodal = document.querySelector('.js-modalblock')
const closeButtons = document.querySelector('.js-modal-close')

if(modalButtons){
    modalButtons.addEventListener('click', function(event){
        overlaymodal.classList.remove('disable')
        body.classList.add('lock')

    })
    closeButtons.addEventListener('click', function(event){
        overlaymodal.classList.add('disable')
        body.classList.remove('lock')

    })
}
